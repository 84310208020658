import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { LINK_DISTRIBUTION, PREVIEW, VID } from "../project-config";

export default function MainCategories() {
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );
  const companyNameState = useSelector(
    state => state.loginReducer.companyname,
    shallowEqual
  );

  const isLoggedInState = loginNameState || companyNameState;

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  useEffect(() => {
    if (
      navCatsState &&
      navCatsState.childs &&
      navCatsState.childs.length > 0 &&
      navCatsState.childs[0].childs &&
      isLoggedInState
    ) {
      setNumberOfChildren(navCatsState.childs[0].childs.length + 1);
    } else {
      setNumberOfChildren(navCatsState.childs[0].childs.length);
    }
  }, [isLoggedInState]);

  return (
    <aside>
      <div className="category-container main-categories">
        {navCatsState.childs.map((cat, index) => {
          return (
            <div key={cat.cid} className="parent-cat-wrapper">
              <h1 dangerouslySetInnerHTML={{ __html: cat.description }}></h1>
              <div className="divider-gray-gradient"></div>
              <div className="main-category-container">
                {cat.childs.map((c, i) => {
                  return (
                    <div
                      key={c.cid}
                      className="main-category-wrapper"
                      style={{
                        width: `calc(${100 / numberOfChildren}%`,
                        paddingTop: `${100 / numberOfChildren}%`
                      }}
                    >
                      <div className="main-category-item">
                        <Link role="button" to={`${PREVIEW}/${c.URL}`}>
                          <figure
                            style={{
                              backgroundImage: `url(${LINK_DISTRIBUTION}/store${c.image})`
                            }}
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: c.description
                              }}
                            ></p>
                          </figure>
                        </Link>
                      </div>
                    </div>
                  );
                })}
                {isLoggedInState && (
                  <div
                    className="main-category-wrapper"
                    style={{
                      width: `calc(${100 / numberOfChildren}%`,
                      paddingTop: `${100 / numberOfChildren}%`
                    }}
                  >
                    <div className="main-category-item">
                      <a
                        role="button"
                        href={`${LINK_DISTRIBUTION}/myaccount.html?mode=viewmixorder&vid=${VID}&iu=${langState}`}
                      >
                        <figure
                          style={{
                            backgroundImage: `url(${LINK_DISTRIBUTION}/store/${VID}/images/icons/reorder-icon.png)`
                          }}
                        >
                          <p>Commande Renouvelée / État De La Commande</p>
                        </figure>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </aside>
  );
}
