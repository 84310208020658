import React, { useCallback, useEffect, useState, useRef } from "react";
import TiffCanvas from "./TiffCanvas";
import UTIF from "utif";
import { useDropzone } from "react-dropzone";
import {
  LINK_DISTRIBUTION,
  VID,
  PREVIEW,
  BASE_PATH
} from "../../../../../project-config";
import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";
import handleUploadLogo from "../utils/handleUploadLogo";
import close from "../../../../../assets/icons/close.svg";

import classes from "./LogoUpload.module.css";

export default function LogoUpload({
  setInputsState,
  logoInputField,
  progressState,
  dispatch,
  validationErrors
}) {
  const tiffLogo = progressState.logo.tiff;

  const logoUrl = (logoFile, relativePath = false) => {
    if (logoFile)
      return `${
        relativePath ? PREVIEW : LINK_DISTRIBUTION
      }/store/${VID}/assets/logos/upload/${logoFile}`;
    else return "";
  };

  console.info("validationErrors3", validationErrors);

  const requiredValidationFailed = Object.keys(validationErrors).some(
    key => key === "logo-upload-wrapper"
  );

  const [fileValidate, setFileValidate] = useState(null);
  const validateFile = file => {
    let validationMessage = "";
    if (
      !["jpeg", "jpg", "tiff", "bmp", "png", "gif"].some(type =>
        file.type.includes(type)
      )
    ) {
      validationMessage =
        "Erreur: le format de fichier est incorrect. Les formats acceptés sont: jpeg, jpg, tiff, bmp, png, gif";
    } else if (file.size / 1024 / 1000 > 10) {
      validationMessage =
        "Erreur: La taille de téléchargement du fichier de 10 Mo a été dépassée";
    }

    setFileValidate(validationMessage);

    return !validationMessage;
  };
  const uploadedLogo = progressState.logo.uploadedLogo;

  const handleTiffImageUrlToArrayBuffer = url => {
    const xhr = new XMLHttpRequest();

    xhr.open("GET", url);
    xhr.responseType = "arraybuffer";
    xhr.onload = imgLoaded;
    xhr.send();

    function imgLoaded(e) {
      let ifds = UTIF.decode(e.target.response);
      UTIF.decodeImage(e.target.response, ifds[0]);
      let rgba = UTIF.toRGBA8(ifds[0]); // Uint8Array with RGBA pixels
      dispatch(
        setPersonalizedQuestionsProgressAction({
          logo: {
            ...progressState.logo,
            uploadedLogo: null,
            tiff: {
              width: ifds[0].width,
              height: ifds[0].height,
              data: rgba
            }
          }
        })
      );

      // console.log("BUFFER", ifds[0].width, ifds[0].height, ifds[0], rgba);
    }

    // binaryStr = bufferArray;
  };

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    let newFile = acceptedFiles.pop();
    if (validateFile(newFile)) {
      const reader = new FileReader();
      const callBack = (json, tiffImage) => {
        if (json && json.fileName && json.longDir) {
          setInputsState({
            [logoInputField.detailID]: [json.fileName, json.longDir]
          });
          console.log("logo upload", json, tiffImage);
          if (tiffImage) {
            handleTiffImageUrlToArrayBuffer(`${BASE_PATH}${json.longDir}`);
          } else {
            dispatch(
              setPersonalizedQuestionsProgressAction({
                logo: {
                  ...progressState.logo,
                  uploadedLogo: `${BASE_PATH}${json.longDir}`,
                  tiff: null
                }
              })
            );
          }
        } else {
          dispatch(
            setPersonalizedQuestionsProgressAction({
              logo: {
                ...progressState.logo,
                uploadedLogo: null,
                tiff: null
              }
            })
          );
        }
      };

      // if (binaryStr.includes("image/tif")) {
      // }

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        let binaryStr = reader.result;

        let tiffImage = false;

        if (binaryStr.includes("image/tif")) tiffImage = true;
        /* 
           dispatch(
          setPersonalizedQuestionsProgressAction({
            logo: {
              ...progressState.logo,
              uploadedLogo: binaryStr,
              tiff: null
            }
          })
        ); */

        handleUploadLogo(
          logoInputField.detailID,
          binaryStr,
          callBack,
          tiffImage
        );
      };
      reader.readAsDataURL(newFile);
    }
  }, []);

  useEffect(() => {
    if (logoInputField.customerInput) {
      // set the previously uploaded logo to be displayed if it exists
      dispatch(
        setPersonalizedQuestionsProgressAction({
          logo: {
            ...progressState.logo,
            uploadedLogo: logoUrl(logoInputField.customerInput)
          }
        })
      );

      setInputsState({
        [logoInputField.detailID]: [
          logoInputField.customerInput,
          logoUrl(logoInputField.customerInput, true)
        ]
      });
    }
    return () => {
      setInputsState({
        [logoInputField.detailID]: null
      });
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div id="logo-upload-wrapper" className={classes.wrapper}>
      <p className={classes.contact}>
        Besoin d'aide? Appelez le &nbsp;
        <a href="tel:+1833-543-4192">1-833-543-4192</a>
      </p>
      <p style={{ lineHeight: "initial" }}>
        *Remarque : les logos couleur téléchargés seront convertis en logos
        monochromes en niveaux de gris pour l'impression. Veuillez demander une
        preuve ci-dessous si vous souhaitez voir une représentation précise de
        votre produit.
      </p>
      <p className={classes.uploadSizeText}>
        La taille du fichier ne doit pas dépasser 10 Mo
      </p>
      <p style={{ lineHeight: "initial" }}>
        Les formats acceptés sont: jpeg, jpg, tiff, bmp, png, gif
      </p>
      <div
        className={`${classes.dropZone}${
          uploadedLogo || tiffLogo ? " " + classes.haveLogo : ""
        }${
          requiredValidationFailed ? " " + classes.inputValidationFailed : ""
        }`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {fileValidate && <p className={classes.fileValidate}>{fileValidate}</p>}
        {isDragActive ? (
          <p>Déposez les fichiers ici...</p>
        ) : !uploadedLogo && !tiffLogo ? (
          <p>Glissez-déposez ou cliquez ici pour télécharger un logo</p>
        ) : (
          <React.Fragment>
            {uploadedLogo && (
              <img
                className={classes.logo}
                src={uploadedLogo}
                alt="uploaded logo"
              />
            )}
            {tiffLogo && <TiffCanvas classes={classes} tiffLogo={tiffLogo} />}
            <img
              src={close}
              className={`${classes.logoRemove}`}
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  setPersonalizedQuestionsProgressAction({
                    logo: {
                      ...progressState.logo,
                      uploadedLogo: null,
                      tiff: null
                    }
                  })
                );
              }}
            ></img>
          </React.Fragment>
        )}
      </div>
      {requiredValidationFailed && (
        <p className={classes.validationText}>Le logo est requis</p>
      )}
    </div>
  );
}
