import React, { useEffect, useState, useContext, lazy, Suspense } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@material-ui/core/Grid";
import "../assets/css/menu.css";
import "../assets/css/minified.css";
import history from "../history.js";
import CategoryItems from "../components/AC-CategoryPage/components/CategoryItems/CategoryItems.jsx";
import Facets from "../components/AC-Facets/Facets.jsx";
import Pagination from "../components/AC-Pagination/Pagination.jsx";

import Loading from "../components/AC-Loading/Loading.jsx";
import CatLoading from "../components/AC-Loading/CatLoading.jsx";
import FacetBreadcrumb from "../components/AC-Breadcrumb/FacetBreadcrumb.jsx";
import Async from "react-code-splitting";
//import SortBy from "../components/AC-SortBy/SortBy.jsx";
import Facets_Mobile from "../components/AC-Facets/Facets_Mobile.jsx";
import { renderPlaceholderCategoryItems } from "../components/AC-CategoryPage/components/CategoryItems/CategoryItems.jsx";
import NoItems from "../components/AC-CategoryPage/components/NoItems/NoItems";

import filter_list from "../assets/icons/filter_list.svg";

import {
  dispatchScroolPage,
  nextPage
} from "../redux/actions/paginationActions.js";
import { I18nContext } from "../i18n";
const CategoryHeader = lazy(() =>
  import(
    "../components/AC-CategoryPage/components/CategoryHeader/CategoryHeader.jsx"
  )
);
let scrollDetectActive = false;
function Category(props) {
  useEffect(() => {
    setTimeout(() => (scrollDetectActive = true), 2000);
  }, []);

  const { translate } = useContext(I18nContext);
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);

  const dispatch = useDispatch();

  const loading = useSelector(
    state => state.categoryReducer.loading,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loadingBottom = useSelector(
    state => state.categoryReducer.loadingBottom,
    shallowEqual
  );

  let pathName = history.location.pathname;

  const pagesState = useSelector(
    state => state.categoryReducer.pages,
    shallowEqual
  );

  const noItemFoundState = useSelector(
    state => state.categoryReducer.noItemFound,
    shallowEqual
  );

  const currentPageState = useSelector(
    state => state.categoryReducer.currentPage,
    shallowEqual
  );

  const scroolPageState = useSelector(
    state => state.categoryReducer.scroolPage,
    shallowEqual
  );

  const buttonsState = useSelector(
    state => state.facetReducer.buttons,
    shallowEqual
  );

  const noButtonState =
    buttonsState &&
    buttonsState.Price &&
    buttonsState.Reviews &&
    Object.keys(buttonsState.Price).length === 0 &&
    Object.keys(buttonsState.Reviews).length === 0;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const cidState = useSelector(
    state => state.categoryReducer.cidN,
    shallowEqual
  );

  const facetsState = useSelector(
    state => state.facetReducer.facets,
    shallowEqual
  );

  const numberOfItemState = useSelector(
    state => state.categoryReducer.numberOfItems,
    shallowEqual
  );

  const numberOfItemsGridSizes = {
    xs: numberOfItemState === 0 ? 12 : 6,
    sm: numberOfItemState === 0 ? 12 : 6,
    md: numberOfItemState === 0 ? 12 : 3
  };
  const handleNextPage = page => {
    if (scroolPageState < pagesState.length || page != 0) {
      dispatch(dispatchScroolPage(page));
      dispatch(nextPage(page, cidState, true));
    } else {
    }
  };

  const handleFilterButtonClicked = () => {
    document.getElementById("root").setAttribute("aria-hidden", true);
    setFilterButtonClicked(true);
  };

  const handleFacetContentCloseIconClicked = () => {
    document.getElementById("root").setAttribute("aria-hidden", false);
    setFilterButtonClicked(false);
  };

  const renderFacets = (
    <React.Fragment>
      <button
        className="mobile-filter-button"
        onClick={() => handleFilterButtonClicked()}
      >
        {translate("mobile_facets.filterButtonText")}
        <img src={filter_list} alt="Icône de filtre"></img>
      </button>
      <Facets_Mobile
        filterButtonClicked={filterButtonClicked}
        handleFacetContentCloseIconClicked={handleFacetContentCloseIconClicked}
      />
    </React.Fragment>
  );

  const renderMobileFacets = <Facets />;

  useEffect(() => {
    if (!loadingBottom && scroolPageState - currentPageState < 4) {
      let handleOnScroll = () => {
        var scrollTop =
          (document.documentElement && document.documentElement.scrollTop) ||
          document.body.scrollTop;
        var scrollHeight =
          (document.documentElement && document.documentElement.scrollHeight) ||
          document.body.scrollHeight;
        var clientHeight =
          document.documentElement.clientHeight || window.innerHeight;
        var scrolledToBottom =
          Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
          handleNextPage(scroolPageState + 1);
        }
      };

      setTimeout(() => {
        window.addEventListener("scroll", handleOnScroll);
      }, 200);

      // returned function will be called on component unmount
      return () => {
        window.removeEventListener("scroll", handleOnScroll);
      };
    }
  }, [scroolPageState]);

  if (noItemFoundState && cidState !== "search") {
    return (
      <div id="bd">
        <div role="main" className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryHeader />
          </Suspense>

          <NoItems />
        </div>
      </div>
    );
  } else if (noItemFoundState && cidState === "search") {
    return (
      <div id="bd">
        <div role="main" className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryHeader />
          </Suspense>
          <div className="sub-nav-menu">
            <h2>{translate("search.noitemfound")}</h2>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div id="bd">
        <div className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryHeader />
          </Suspense>

          <Loading />

          <div className="row">
            <div className="category-container">
              {/*    <DistanceFilter loading={loading} /> */}
              <Grid container justify="space-between" alignItems="center">
                <Grid
                  style={{
                    display:
                      (facetsState && facetsState.length == 0) || noButtonState
                        ? "none"
                        : ""
                  }}
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={3}
                  className="grid-facets-container"
                >
                  {/*<SubCategoryMenu />*/}
                  {!isMobileState ? renderMobileFacets : renderFacets}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={8}
                  md={8}
                  lg={9}
                  className={`grid-category-items-container${
                    (facetsState && facetsState.length == 0) || noButtonState
                      ? " no-facets"
                      : ""
                  }`}
                >
                  <Grid container justify="space-around" alignItems="center">
                    <Grid item xs={12} style={{ margin: "0 1%" }}>
                      <FacetBreadcrumb />
                    </Grid>
                    <Grid
                      className="pagination-container"
                      item
                      xs={12}
                      style={{
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        margin: "0 1%"
                      }}
                    >
                      <Grid
                        className="pagination-container"
                        item
                        xs={12}
                        style={{
                          backgroundColor: "#f5f5f5",
                          padding: "10px",
                          margin: "0 1%"
                        }}
                      >
                        <Grid
                          container
                          justify="space-around"
                          alignItems="center"
                        >
                          {/* <Grid item {...numberOfItemsGridSizes}>
                              <NumberOfItems />
                            </Grid> */}

                          <Grid item xs={12} sm={12} md={6}>
                            <Pagination />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {renderPlaceholderCategoryItems()}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="bd">
        <div className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryHeader />
          </Suspense>

          <div
            className="row"
            style={{
              paddingTop: "0px"
            }}
          >
            <div className="category-container">
              <Grid container justify="space-between" alignItems="center">
                <Grid
                  style={{
                    display:
                      numberOfItemState === 0 || noButtonState ? "none" : ""
                  }}
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={3}
                  className="grid-facets-container"
                >
                  {!isMobileState ? renderMobileFacets : renderFacets}
                </Grid>
                <Grid
                  item
                  xs={numberOfItemState !== 0 ? 6 : 12}
                  sm={numberOfItemState !== 0 ? 8 : 12}
                  md={numberOfItemState !== 0 ? 8 : 12}
                  lg={numberOfItemState !== 0 ? 9 : 12}
                  className={`grid-category-items-container${
                    (facetsState && facetsState.length == 0) || noButtonState
                      ? " no-facets"
                      : ""
                  }`}
                >
                  <Grid container justify="space-around" alignItems="center">
                    <Grid item xs={12} style={{ margin: "0 1%" }}>
                      <FacetBreadcrumb />
                    </Grid>
                    <Grid className="pagination-container" item xs={12}>
                      <Grid container justify="flex-end" alignItems="center">
                        {/*   <Grid item {...numberOfItemsGridSizes}>
                          <NumberOfItems />
                        </Grid> */}

                        <Grid item xs={12} sm={12} md={12}>
                          <Pagination />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <CategoryItems />
                      {loadingBottom ? <CatLoading /> : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <div><img src="https://cdn.postary.com/wp-content/uploads/2019/04/11-postcard-marketing-tips-1200x500.png" width="100%"/></div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Category;
