const staticContents = [
  {
    url: "terms-and-conditions",
    title: "Termes et conditions",
    description: "Termes et conditions"
  },
  {
    url: "privacy-policy",
    title: "Politique de confidentialité",
    description: "Politique de confidentialité"
  },
  {
    url: "accessibility",
    title: "Accessibilité",
    description: "Politique d'accessibilité du site Web"
  }
];

module.exports = staticContents;
