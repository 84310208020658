import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { LINK_DISTRIBUTION } from "../../../../../project-config";
import { handleSelectedInkColor } from "../utils/handleSelectedInkColor";
import { handleSelectedLogoOption } from "../utils/logoSelectionHandler";
import ReverseNumberingExtended from "./modules/ReverseNumberingExtended";
import classes from "./RadioButtonGroup.module.css";

export default function RadioButtonGroup({
  progressState,
  state,
  savedInputsState,
  setState,
  buttons,
  label,
  name,
  type,
  firstOptionIsCheckedByDefault,
  dispatch,
  dependedFields,
  InkColorQuestion,
  needsCheckboxActivation,
  setValidations,
  failedValidations,
  questionCode,
  specialInstructionsQuestion,
  firstQuestion,
  progress
}) {
  const [active, setActive] = useState(!needsCheckboxActivation);

  const [reverseNumberingState, setReverseNumberingState] = useState({
    active: false
  });

  const inputRef = useRef();

  useEffect(() => {
    if (progressState.progress === progress && firstQuestion) {
      if (inputRef && inputRef.current) {
        console.log(
          "progressState22",
          progressState.progress,
          progress,
          inputRef.current
        );
        setTimeout(() => {
          inputRef.current.focus();
        }, 50);
      }
    }
  }, [progressState.progress]);

  const [validationMessage, setValidationMessage] = useState(null);
  useEffect(() => {
    if (failedValidations && failedValidations.validationType === "required") {
      setValidationMessage("Requis");
    } else if (
      failedValidations &&
      failedValidations.validation === "invalid"
    ) {
      setValidationMessage("Veuillez sélectionner l'une des options suivantes");
    } else {
      setValidationMessage(null);
    }
  }, [failedValidations]);

  useEffect(() => {
    const previouslyChecked = buttons.find(
      b => b.checked === true || savedInputsState[name] == b.id
    );

    let validations = {};
    if (buttons.length > 0) {
      buttons.forEach(c => {
        if (c && c.validations) {
          if (validations.hasOwnProperty(name) === false) {
            validations[name] = {};
          }
          // set active if setActiveOnMount
          if (c.validations.setActiveOnMount) {
            c.validations.active = true;
          }

          validations[name][c.id] = c.validations;
        }
      });
    }

    setValidations(validations);

    if (previouslyChecked) {
      yesOptionCheckedForReverseNumbering(previouslyChecked.id, true);
      setState({ [name]: previouslyChecked.id });
      handleSelectedLogoOption(
        previouslyChecked.id,
        label,
        buttons,
        dispatch,
        progressState
      );

      handleSelectedInkColor(
        previouslyChecked.id,
        label,
        buttons,
        dispatch,
        progressState
      );
      return;
    }
    if (
      buttons &&
      buttons[0] &&
      firstOptionIsCheckedByDefault &&
      !needsCheckboxActivation
    ) {
      handleSelectedLogoOption(
        buttons[0].id,
        label,
        buttons,
        dispatch,
        progressState
      );

      handleSelectedInkColor(
        buttons[0].id,
        label,
        buttons,
        dispatch,
        progressState
      );

      setState({ [name]: buttons[0].id });
    }
  }, []);

  const handleOnChange = (e, key, id) => {
    let value = null;
    let name = null;
    if (e) {
      value = e.target.value;
      name = e.target.name;
    }

    if (questionCode.toLowerCase().includes("reversenbringind")) {
      const label = e.target.dataset && e.target.dataset.label;

      if (label.toLowerCase() == "oui") {
        setReverseNumberingState(reverseNumberingState => {
          return { ...reverseNumberingState, active: true };
        });
      } else {
        setReverseNumberingState(reverseNumberingState => {
          return { ...reverseNumberingState, active: false };
        });
      }
    }

    handleSelectedLogoOption(
      value || id,
      label,
      buttons,
      dispatch,
      progressState,
      setState,
      dependedFields
    );
    handleSelectedInkColor(
      value || id,
      label,
      buttons,
      dispatch,
      progressState
    );
    setState({ [name || key]: value || id });
  };

  const handleCheckboxChanged = e => {
    const { checked } = e.target;

    setActive(checked);

    setValidations({ [name]: checked ? "required" : null });

    if (!checked) {
      let value = "";
      handleSelectedLogoOption(
        value,
        label,
        buttons,
        dispatch,
        progressState,
        setState,
        dependedFields
      );

      handleSelectedInkColor(value, label, buttons, dispatch, progressState);
      setState({ replace: true, key: name });
    }
  };

  const renderSelectedFontLabel = () => {
    if (InkColorQuestion)
      return (
        <span className={classes.selectedFontLabel}>
          {progressState && progressState.font && progressState.font[label]}
        </span>
      );
  };

  const yesOptionCheckedForReverseNumbering = (id, setActive = false) => {
    if (questionCode.toLowerCase().includes("reversenbringind")) {
      const optionNo = buttons.find(btn => btn.label.toLowerCase() === "non");
      const optionNoId = optionNo && optionNo.id;

      const optionYes = buttons.find(btn => btn.label.toLowerCase() === "oui");
      const optionYesId = optionYes && optionYes.id;

      if (optionNoId !== id) {
        if (setActive) {
          setReverseNumberingState({ ...reverseNumberingState, active: true });
        }
      }

      if (optionNoId !== state[name] && id === optionYesId) {
        return true;
      }
      return false;
    }
  };

  return (
    <React.Fragment>
      <div
        id={name}
        className={`${classes.wrapper}${
          validationMessage ? " " + classes.inputValidationFailed : ""
        }`}
      >
        {label && (
          <p>
            {needsCheckboxActivation && (
              <input
                id={label}
                checked={active}
                onChange={handleCheckboxChanged}
                type="checkbox"
              />
            )}
            <label htmlFor={label}>
              {label}:&nbsp;{renderSelectedFontLabel()}
            </label>
          </p>
        )}
        {buttons.map((btn, index) => {
          if (
            // Don't render the other questions for reverse numbering
            questionCode.toLowerCase().includes("reversenbringind") &&
            !["oui", "non"].includes(btn.detailDesc.toLowerCase())
          ) {
            return null;
          }

          return (
            <div
              key={btn.id}
              className={`${classes.radioBtnWrapper}${
                type ? " " + classes[type] : ""
              }${InkColorQuestion ? " " + classes.inkColor : ""}${
                state[name] === btn.id ? " " + classes.active : ""
              }`}
              role="radiogroup"
              aria-label={label}
            >
              <input
                ref={firstQuestion && index === 0 ? inputRef : null}
                checked={
                  !!(
                    (state[name] && state[name] === btn.id) ||
                    yesOptionCheckedForReverseNumbering(btn.id)
                  )
                }
                className={`${classes.radioBtn}`}
                onChange={handleOnChange}
                type="radio"
                id={btn.id}
                name={name}
                value={btn.id}
                data-label={btn.label}
                aria-label={`${btn.label} ${
                  index === 0 && validationMessage ? validationMessage : ""
                }`}
              />
              {type === "color" && InkColorQuestion && (
                <React.Fragment>
                  <img
                    tabIndex={"0"}
                    className={active ? "" : classes.disabled}
                    onClick={() => {
                      if (active) handleOnChange(null, name, btn.id);
                    }}
                    title={active ? btn && btn.label : "Disabled"}
                    src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                    alt={btn && btn.label}
                  ></img>
                </React.Fragment>
              )}
              {type === "color" && !InkColorQuestion && (
                <React.Fragment>
                  <img
                    src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                    alt={btn && btn.label}
                  ></img>
                </React.Fragment>
              )}
              {type === "image" && (
                <img
                  src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                  alt={btn && btn.label}
                ></img>
              )}
              {type !== "image" && <label htmlFor={btn.id}>{btn.label}</label>}
            </div>
          );
        })}
        {validationMessage && (
          <p className={classes.validationMessage}>{validationMessage}</p>
        )}
        {reverseNumberingState.active && (
          <ReverseNumberingExtended
            /* specialInstructionsQuestion={specialInstructionsQuestion} */
            state={state}
            setState={setState}
            buttons={buttons}
            name={name}
          />
        )}
      </div>
    </React.Fragment>
  );
}
