import React, { useRef, useEffect } from "react";
import { maskChars } from "../../../../../functions/Utilities";
import {
  LINK_DISTRIBUTION,
  PREVIEW,
  BASE_PATH
} from "../../../../../project-config";
import { jumpToReviewTabOnCustomizerAction } from "../../../../../redux/actions/productAction";

import classes from "./ReviewSection.module.css";
import TiffCanvas from "./TiffCanvas";

export default function ReviewSection({
  dispatch,
  questions,
  inputs,
  progressState,
  maskedInputIds
}) {
  const tiffLogo = progressState.logo.tiff;

  const firstQuestionRef = useRef(null);

  useEffect(() => {
    if (progressState.progress === 3 && firstQuestionRef.current) {
      setTimeout(() => {
        firstQuestionRef.current.focus();
      }, 50);
    }
  }, [progressState.progress]);

  return (
    <div className={classes.wrapper}>
      {questions.map((question, index) => {
        return (
          <div
            ref={index === 0 ? firstQuestionRef : null}
            tabIndex={progressState.progress === 3 ? "0" : "-1"}
            key={question.quesID}
            className={classes.inputContainer}
          >
            <h3 className={classes.title}>{question.quesDesc}</h3>
            {question.quesDetails.length === 0 &&
              inputs &&
              inputs[question.quesID] && <span>{inputs[question.quesID]}</span>}
            {question.quesDetails.map(ques => {
              if (question.quesCode.includes("logo-upload")) {
                return (
                  <div key={ques.detailID}>
                    {inputs[ques.detailID] &&
                      (tiffLogo ? (
                        <TiffCanvas tiffLogo={tiffLogo} classes={classes} />
                      ) : (
                        <img
                          className={classes.uploadedLogo}
                          src={`${BASE_PATH}${
                            inputs[ques.detailID] &&
                            inputs[ques.detailID][1] &&
                            inputs[ques.detailID][1]
                          }`}
                          alt="uploaded logo"
                        />
                      ))}
                  </div>
                );
              } else if (
                inputs &&
                inputs[question.quesID] &&
                inputs[question.quesID] === String(ques.detailID)
              ) {
                return (
                  <div key={ques.detailID} className={classes.inputWrapper}>
                    {ques &&
                    ques.detailImg &&
                    question.quesCode.toLowerCase().includes("logooptions") ===
                      false ? (
                      <>
                        <img
                          style={{ maxHeight: "200px" }}
                          src={LINK_DISTRIBUTION + "/" + ques.detailImg}
                          alt={ques.previewVal}
                        ></img>
                        <label>{ques.label}</label>
                      </>
                    ) : question.quesType.toLowerCase().includes("color") ? (
                      <span
                        className={classes.colorBox}
                        style={{
                          backgroundColor: ques.previewVal.toLowerCase()
                        }}
                      ></span>
                    ) : (
                      <span>{ques.detailDesc}</span>
                    )}
                  </div>
                );
              } else if (
                (question.quesInput === "text" ||
                  question.quesInput === "checkbox") &&
                inputs &&
                inputs[ques.detailID] !== null
              ) {
                let input = inputs[ques.detailID];

                if (maskedInputIds.includes(ques.detailID)) {
                  input = maskChars(input, 4);
                }

                input = input === true ? "Yes" : input === false ? "No" : input;

                return (
                  <div key={ques.detailID} className={classes.inputWrapper}>
                    <label>{ques.detailDesc}</label>
                    <span
                      className={classes.reviewInput}
                      dangerouslySetInnerHTML={{
                        __html: input === "" ? "" : input
                      }}
                    ></span>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      })}
    </div>
  );
}
