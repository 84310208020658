export const VID = process.env.REACT_APP_VID;
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
const PREVIEW_PROJECT_LINK = process.env.REACT_APP_PREVIEW_PROJECT_LINK;
const PUBLISH_PROJECT_LINK = process.env.REACT_APP_PUBLISH_PROJECT_LINK;
const STORE_LINK_ROOT = process.env.REACT_APP_STORE_LINK;

export const IS_PUBLISHED =
  process.env.REACT_APP_IS_PUBLISHED == "true" ? true : false;
export const PREVIEW = IS_PUBLISHED ? "" : "/preview";

export const PROJECT_LINK = IS_PUBLISHED
  ? PUBLISH_PROJECT_LINK
  : PREVIEW_PROJECT_LINK;

export const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export const STORE_LINK = STORE_LINK_ROOT + PREVIEW + "/store";

const locationFunc = () => {
  let location;
  let link;
  let preview;
  if (IS_PUBLISHED) {
    preview = "/shop";
    link = PUBLISH_PROJECT_LINK;
  } else {
    preview = "/preview";
    link = PREVIEW_PROJECT_LINK;
  }

  if (window.location.host.includes("localhost")) {
    location = link;
  } else {
    location = preview;
  }

  return location;
};

export const LINK_DISTRIBUTION = locationFunc();
