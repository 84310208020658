import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import classes from "./Select.module.css";

export default function Select({
  disabled,
  packagePrices,
  state,
  setState,
  field,
  options,
  label,
  defaultValue,
  refHook,
  tabIndex
}) {
  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const [selectedQuantity, setSelectedQuantity] = useState(
    packagePrices && state && state.text
  );

  const [selectCopiesAttrId, setSelectCopiesAttrId] = useState(null);

  useEffect(() => {
    if (packagePrices && attributeDetailsState) {
      const attr =
        attributeDetailsState &&
        attributeDetailsState.find(attr =>
          ["Choisissez nombre de copies"].includes(attr.dropname)
        );

      if (attr) {
        setSelectCopiesAttrId(attr.attributeid);
      }
    }
  }, [attributeDetailsState]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedQuantity(Number(defaultValue));
      if (foundPackage) {
        const foundPrice = foundPackage.prices.find(
          p => p.quantity.toString() === defaultValue.toString()
        );
        if (foundPrice)
          setState({
            ...state,
            text: foundPrice.quantity,
            value: foundPrice.price
          });
      } else {
        let foundOption = options.find(
          opt => parseInt(opt.text) === Number(defaultValue)
        );

        if (foundOption) {
          if (packagePrices) foundOption.text = parseInt(foundOption.text);
          setState({
            ...state,
            ...foundOption
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (state && state.text) {
      setSelectedQuantity(state.text);
    }
  }, [state && state.text]);

  const [foundPackage, setFoundPackage] = useState(null);
  useEffect(() => {
    if (
      packagePrices &&
      selectedProductAttributesState &&
      selectedProductAttributesState[itemIdState] &&
      selectedProductAttributesState[itemIdState][selectCopiesAttrId] &&
      selectedProductAttributesState[itemIdState][selectCopiesAttrId].ddtext &&
      selectCopiesAttrId
    ) {
      const ddtext =
        selectedProductAttributesState[itemIdState] &&
        selectedProductAttributesState[itemIdState][selectCopiesAttrId] &&
        selectedProductAttributesState[itemIdState][selectCopiesAttrId].ddtext;

      const foundPackage = packagePrices.find(p => {
        if (p.label.includes(" ")) {
          const labelNumber = p.label.split(" ")[0];
          if (!ddtext.includes(" ")) {
            return labelNumber === ddtext;
          } else {
            const ddtextFirstWord = ddtext.split(" ")[0];
            return labelNumber === ddtextFirstWord;
          }
        } else {
          return p.label === ddtext;
        }
      });
      if (foundPackage) {
        setFoundPackage(foundPackage);
        const foundPrice = foundPackage.prices.find(
          p => Number(p.quantity) === parseInt(selectedQuantity)
        );
        if (foundPrice)
          setState({
            ...state,
            text: foundPrice.quantity,
            value: foundPrice.price
          });
        else
          setState({
            ...state,
            text: foundPackage.prices[0].quantity,
            value: foundPackage.prices[0].price
          });
      }
    }
  }, [selectedProductAttributesState]);

  const handleOnChange = e => {
    const { value } = e.target;
    setSelectedQuantity(Number(value));
    if (foundPackage) {
      const foundPrice = foundPackage.prices.find(p => p.price == value);
      if (foundPrice)
        setState({
          ...state,
          text: foundPrice.quantity,
          value: foundPrice.price
        });
    } else {
      let foundOption = options.find(opt => opt.value == value);

      if (foundOption) {
        if (packagePrices) foundOption.text = parseInt(foundOption.text);
        setState({
          ...state,
          ...options.find(opt => opt.value == value)
        });
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <label htmlFor={field.replace(/ /g, "-")}>{label}</label>
      <select
        tabIndex={tabIndex ? tabIndex : ""}
        ref={refHook}
        disabled={disabled}
        id={field.replace(/ /g, "-")}
        value={state && state.value}
        name={field}
        onChange={handleOnChange}
      >
        {disabled && (
          <option value={defaultValue}>{`${defaultValue} ${
            defaultValue > 1 ? "Produits" : "Produit"
          }`}</option>
        )}
        {!disabled &&
          options.map(opt => (
            <option
              key={opt.text}
              value={opt.value}
              dangerouslySetInnerHTML={{
                __html: `${opt.text}${opt && opt.label ? " " + opt.label : ""}`
              }}
            ></option>
          ))}
      </select>
    </div>
  );
}
