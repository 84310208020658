import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import FooterContentFunc from "./footerContent";
import AnchorElement from "../../functions/AnchorElement.jsx";
import deluxeLogo from "../../assets/img/deluxe-logo.png";

import expand_less from "../../assets/icons/expand_less_white.svg";
import expand_more from "../../assets/icons/expand_more_white.svg";

import { I18nContext } from "../../i18n/index";
import Profile from "../../header/Profile";
function Footer() {
  const { translate } = React.useContext(I18nContext);

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [footerLinksExpanded, setFooterLinksExpanded] = useState(false);

  const toggleExpandFooterLinks = () => {
    setFooterLinksExpanded(footerLinksExpanded => !footerLinksExpanded);
  };

  const [footerContent, setFooterContent] = useState({});

  useEffect(() => {
    setFooterContent(FooterContentFunc(langState, loginNameState));
    return () => {
      setFooterContent({});
    };
  }, [langState]);

  const links = (
    <div className="footer-links" style={{ marginRight: "auto" }}>
      <p>
        <Link to="/terms-and-conditions">Termes et conditions</Link>
      </p>
      <p>
        <Link to="/privacy-policy">Politique de confidentialité</Link>
      </p>
      <p>
        <Link to="/accessibility">Politique d'accessibilité du site Web</Link>
      </p>
    </div>
  );

  const finePrint = (
    <small style={{ color: "#eee", fontSize: "0.8rem", lineHeight: "2rem" }}>
      Protégé par les droits d'auteur. Tous droits réservés. © Deluxe Enterprise
      Operations.
    </small>
  );
  if (footerContent && Object.keys(footerContent).length > 0) {
    if (isMobileState) {
      return (
        <footer id="footer">
          <div className="new-footer">
            <div className="container">
              <div className="footer-links-cotaniner">
                <button
                  onClick={toggleExpandFooterLinks}
                  className="footer-expand-button"
                >
                  {footerLinksExpanded ? `Collapse` : `Expand`}
                  {footerLinksExpanded ? (
                    <img src={expand_more} alt="expand more"></img>
                  ) : (
                    <img src={expand_less} alt="expand less"></img>
                  )}
                </button>
                <div
                  style={{ display: footerLinksExpanded ? "" : "none" }}
                  className="footer-links-wrapper"
                >
                  {links}
                </div>
              </div>

              <div id="finePrint">
                <div style={{ textAlign: "center" }}>{finePrint}</div>
                <img src={deluxeLogo} alt="Société Deluxe" />
              </div>
            </div>
          </div>
        </footer>
      );
    } else {
      return (
        <footer id="footer">
          <div className="new-footer">
            <div id="finePrint">
              {links}
              <img src={deluxeLogo} alt="Société Deluxe" />
            </div>
            <div style={{ textAlign: "center" }}>{finePrint}</div>
          </div>
        </footer>
      );
    }
  } else {
    return null;
  }
}

export default Footer;
