import { PROJECT_LINK, PREVIEW } from "../../project-config.js";

const content = [
  {
    url: "privacy-california",
    content: `<div class="privacy-california static-container article-post__body">
    <p><strong>Your California Rights</strong></p>
    <p>If you are a California resident, you have certain rights related to your California Personal Information. [You may exercise these rights free of charge except as otherwise permitted under applicable law.]</p>
    <ul>
    <li><strong>Section 100 Rights</strong>. You have the right to request that we disclose to you the specific species of personal information we have collected.</li>
    <br>
    <li><strong>Section 105 Rights</strong>. You have the right to request that we delete California Personal Information about you which we have collected from you.</li>
    <br>
    <li><strong>Section 110 Rights</strong>. You may request that we disclose to you:<ul>
    <li>the categories of California Personal Information we have collected about you;</li>
    <li>the categories of sources from which the California Personal Information is collected;</li>
    <li>our business or commercial purpose for collecting or selling California Personal Information;</li>
    <li>the categories of third parties with whom we share California Personal Information; and</li>
    <li>the specific pieces of information we have collected about you.</li>
    </ul>
    </li>
    <li><strong>Section 115 Rights</strong>. To the extent that we sell your California Personal Information within the meaning of the California Consumer Privacy Act or disclose such information for a business purpose, you may request that we disclose to you:<ul>
    <li>the categories of California Personal Information that we have collected about you;</li>
    <li>the categories of California Personal Information about you that we have sold within the meaning of the California Consumer Privacy Act and the categories of third parties to whom the California Personal Information was sold, by category or categories of personal information for each third party to whom the California personal information was sold; and</li>
    <li>the categories of California Personal Information about you that we disclosed for a business purpose.</li>
    </ul>
    </li>
    <li><strong>Right to Opt Out</strong>. In addition, we may sell your information within the meaning of the California Consumer Privacy Act. You have the right to opt out of such sales here.</li>
    </ul>
    <p>You may request to exercise these rights by:</p>
    <ul>
    <li>Completing our <a href="#" onclick="rightsRequest()">rights request form</a></li>
    <li>Emailing us at <a href="mailto:Privacy@deluxe.com?fsubject=Privacy%20Inquiry&amp;body=1.%09Request%20description%3A%0A2.%09Name%3A%0A3.%09Household%20Address%3A%0A4.%09Email%3A%0A5.%09Phone%20Number%3A%0A%0D%0A%0D%0AThank%20you.">Privacy@deluxe.com</a></li>
    <li>Calling us toll-free at 877-861-8985.</li>
    </ul>
    <p>[As required or permitted under applicable law, please note that we may take steps to verify your identity before granting you access to information or acting on your request to exercise your rights. We may limit our response to your exercise of the above rights as permitted under applicable law.] Subject to applicable law, we may not discriminate against you because of your exercise of any of the above rights, or any other rights under the California Consumer Privacy Act, including by:</p>
    <ul>
    <li>Denying you goods or services;</li>
    <li>Charging different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties;</li>
    <li>Providing you a different level or quality of goods or services; or</li>
    <li>Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
    </ul>
                <p>If you are a California resident, the following provisions apply to our processing of information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household [subject to the California Consumer Privacy Act] (“California Personal Information”). For such residents, the provisions of this California Addendum prevail over any conflicting provisions of the Privacy Notice.</p>
    <p>Deluxe has collected the following categories of California Personal Information within the last 12 months:</p>
    <p>(A) Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers.</p>
    <p>(B) Personal information described in subdivision (e) of Section 1798.80 (California Customer Records statute). This means any information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, his or her name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. </p>
    <p>(C) Characteristics of protected classifications under California or federal law.</p>
    <p>(D) Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p>
    <p>(E) Biometric information.</p>
    <p>(F) Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement.</p>
    <p>(G) Geolocation data.</p>
    <p>(H) Audio, electronic, visual, thermal, olfactory, or similar information.</p>
    <p>(I) Professional or employment-related information.</p>
    <p>(J) Education information, defined as information that is not publicly available personally identifiable information as defined in the Family Educational Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99).</p>
    <p>(K) Inferences drawn from any of the information identified in this subdivision to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
    <p>Within the last 12 months, within the meaning of the California Consumer Privacy Act, we have sold California Personal Information identified in the above categories A-K.</p>
    <p>Within the last 12 months, we have disclosed California Personal Information identified in the above categories (A)-(K) for our business purposes. To learn more about the categories of third parties with whom we share such information, please see <a href="https://www.deluxe.com/policy/">/policy</a>.  </p>
    <p>We obtain California Personal Information from a variety of sources. [These sources include: yourself, with respect to both online and offline interactions you may have with us or our service providers;  other entities with whom you transact; others with whom you maintain relationships who may deal with us on your behalf; the devices you use to access our websites, mobile applications, and online services; credit bureaus; identify verification and fraud prevention services; marketing and analytics providers; public databases; social media platforms; and others consistent with this Privacy Notice.]  Please see <a href="https://www.deluxe.com/policy/">/policy</a>.</p>
    <p>We use the California Personal Information we collect for the business purposes disclosed within this Privacy Notice. Please see <a href="https://www.deluxe.com/policy/">/policy</a>. Please note that the business purposes for which we may use your information include:</p>
    <ul>
    <li>Audits and reporting relating to particular transactions and interactions, including online interactions, you may have with us or others on our behalf;</li>
    <li>Detecting and protecting against security incidents, and malicious, deceptive, fraudulent or illegal activity, and prosecuting the same;</li>
    <li>Debugging to identify and repair errors in our systems;</li>
    <li>Short-term, transient use including contextual customization of ads;</li>
    <li>Providing services on our behalf or on behalf of another, including maintaining or servicing accounts, providing customer service, fulfilling transactions, verifying identity information, processing payments, and other services;</li>
    <li>Conducting internal research to develop and demonstrate technology; and</li>
    <li>Conducting activity to verify, enhance, and maintain the quality or safety of services or devices which we may own, control, or provide.</li>
    </ul>
    
             </div>`
  },
  {
    url: "terms-and-conditions",
    content: `<main><div class="static-container">
    <h1>Modalités</h1>
 
    <p>Modalités</p>
     
    <p>Deluxe Financial Services, Inc. («Deluxe») gère le site ChequesCanada (le «Site»). Votre accès au Site et l'utilisation que vous en faites, y compris tout achat effectué sur le Site, sont assujettis aux modalités suivantes («Modalités»). En accédant au Site et en l'utilisant, vous acceptez les Modalités sans restriction ni réserve. SI VOUS N'ACCEPTEZ PAS CES MODALITÉS, N'ACCÉDEZ PAS À CE SITE OU NE L'UTILISEZ PAS. DELUXE PEUT À TOUT MOMENT MODIFIER LES MODALITÉS EN ACTUALISANT CETTE PUBLICATION. L'UTILISATEUR EST LIÉ PAR TOUTE MODIFICATION ET VOUS DEVRIEZ DONC VISITER PÉRIODIQUEMENT CETTE PAGE POUR CONSULTER LES MODALITÉS ACTUALISÉES AUXQUELS VOUS ÊTES LIÉ.</p>
    
    
    
    <p>A. Utilisation du Site</p>
    
    
    <p>1. Vous pouvez télécharger les documents affichés sur le Site uniquement pour un usage personnel et non commercial, à condition que vous ne supprimiez ni ne modifiiez aucune marque de commerce, aucun droit d'auteur ou tout autre avis de propriété contenu sur les documents. Vous ne pouvez cependant pas distribuer, modifier, transmettre, réutiliser, signaler ou utiliser le contenu du Site – incluant le texte et les images – à des fins publiques ou commerciales sans l'autorisation écrite préalable de Deluxe.</p>
    
    <p>2. Vous devez supposer que tout ce que vous voyez ou lisez sur le Site est protégé par le droit d'auteur, sauf indication contraire, et ne peut être utilisé que tel que le prévoient les Modalités (et le texte sur le Site) sans l'autorisation écrite préalable de Deluxe. Deluxe ne garantit ni n'affirme que votre utilisation des documents affichés sur le site n'enfreindra pas les droits de tiers.</p>
    
    <p>3. Deluxe n'offre aucune garantie et ne fait aucune affirmation quant à l'exactitude ou l'intégralité des informations et des documents contenus sur le Site et Deluxe n'assume aucune responsabilité pour toute inexactitude, erreur ou omission dans le contenu du Site.</p>
    
    <p>4. Vous utilisez ce Site et y naviguez à vos risques. Ni Deluxe, ni aucune autre partie impliquée dans la création, la production, la maintenance ou la distribution du Site ne sont responsables de dommages directs, indirects, spéciaux, accessoires, corrélatifs ou autres de toute nature ou description résultant de votre accès au Site ou de votre utilisation du Site. Sans limiter ce qui précède, tout sur le Site vous est fourni «TEL QUEL» SANS GARANTIE D'AUCUNE SORTE, EXPLICITE OU IMPLICITE, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D'ADAPTATION À UN USAGE PARTICULIER ET D'ABSENCE DE CONTREFAÇON. Prenez note que certaines juridictions peuvent ne pas autoriser l'exclusion des garanties implicites, de sorte que certaines des exclusions ci-dessus pourraient ne pas s'appliquer à vous. Deluxe n'assume également aucune responsabilité et ne sera pas responsable de dommages ou de virus qui pourraient infecter votre équipement informatique ou tout autre bien en raison de votre accès, utilisation ou navigation sur le Site ou de votre téléchargement de documents, de données, de textes ou d'images à partir du Site.</p>
    
    
    <p>5. Les communications et documents que vous transmettez au Site par courrier électronique ou autre, comme des données, questions, commentaires, suggestions ou autres, sont et seront traités comme non confidentiels et non exclusifs, sauf si requis par la loi et notre politique de confidentialité. Tout ce que vous transmettez ou publiez peut être utilisé par Deluxe ou ses sociétés affiliées à n'importe quelle fin autorisée par la loi ou notre politique de confidentialité, y compris, mais sans s'y limiter, la reproduction, la divulgation, la transmission, la publication et la diffusion. De plus, Deluxe est libre d'utiliser, sans limitation ni restriction, toutes les idées ou techniques, tous les concepts et tout le savoir-faire que contient toute communication que vous envoyez vers le Site ou par le biais du Site à quelque fin que ce soit, y compris, mais sans s'y limiter, l'élaboration, la fabrication et la commercialisation de produits et services utilisant ces informations.</p>
    
    
    <p>6. Les images de personnes ou de produits affichées sur le Site sont soit la propriété de Deluxe, soit utilisées en vertu de son autorisation. L'utilisation de ces images par vous, ou par toute autre personne autorisée par vous, est interdite, à l'exception de l'utilisation des icônes de liens conformément aux directives de Deluxe. Toute utilisation non autorisée des images peut enfreindre des lois en vigueur, y compris les lois sur le droit d'auteur, les lois sur les marques de commerce, et les lois sur la confidentialité et la publicité.</p>
    
    
    <p>7. Deluxe n'a pas vérifié tous les sites liés au Site et n'est pas responsable du contenu des pages hors Site ou de tout autre site lié au Site ou des produits et services offerts par ou via ces sites ou de la confidentialité, la sécurité des données ou d'autres pratiques commerciales des entités qui possèdent ou maintiennent de tels sites. Votre connexion vers toute autre page hors Site ou d'autres sites, et l'utilisation que vous en faites, sont à vos risques.</p>
    
    
    
    <p>8. Il vous est interdit de publier ou de transmettre à Deluxe ou au Site des informations, des documents ou des images non autorisés ou détournés ou des informations, de la documentation ou des images illicites, menaçantes, calomnieuses, diffamatoires, obscènes, scandaleuses, incendiaires, pornographiques ou profanes, ou des informations, de la documentation ou des images qui pourrait constituer ou encourager une conduite qui serait considérée comme une infraction criminelle, donnerait lieu à une responsabilité civile ou enfreindrait toute loi. La politique de Deluxe est de coopérer pleinement avec toute autorité chargée de l'application de la loi ou avec toute ordonnance d'un tribunal demandant ou ordonnant à Deluxe de divulguer l'identité de toute personne publiant ou transmettant de telles informations, documentions ou images.</p>
    
    
     
    
    
    
    <p>B. Propriété intellectuelle</p>
     
    <p>Sauf indication contraire dans le texte du Site, les marques de commerce, les logos, les marques de service et tous les éléments protégés par le droit d'auteur affichés sur le Site constituent une propriété intellectuelle détenue par Deluxe ou que Deluxe a le droit d'utiliser sur le Site (la «Propriété intellectuelle de Deluxe»). Aucun élément sur le Site ne doit être interprété comme accordant, implicitement, par préclusion ou autrement, une licence ou un droit d'utiliser toute Propriété intellectuelle de Deluxe affichée sur le Site sans l'autorisation écrite préalable de Deluxe. L'utilisation non autorisée de la Propriété intellectuelle de Deluxe affichée sur le Site, ou de tout autre contenu sur le Site, est strictement interdite. Prenez note que Deluxe fera valoir activement ses droits de propriété intellectuelle dans toute la mesure de la loi, y compris par des poursuites judiciaires.</p>
    
    
    
    
    <p>C. Achats de produits</p>
    
    
    
    <p>1. Le paiement de votre commande s'effectuera uniquement par débit du compte pour lequel vous passez votre commande de chèques. En passant la commande, vous autorisez ce débit par l'entremise d'une chambre de compensation automatisée.
    
    
    <p>2. Les prix facturés sont FOB notre emplacement. Les taxes de vente nationales et locales seront ajoutées au montant de votre commande, le cas échéant.</p>
    
    
    <p>3. Tous les prix et produits sont susceptibles d'être modifiés ou discontinués sans préavis ni obligation. Les frais d'expédition, de manutention, de traitement et de service peuvent varier. Tous les prix sont en dollars américains. Vérifiez auprès de votre institution financière pour obtenir des renseignements sur les prix. Votre institution financière établit le prix que vous payez pour votre commande de chèques.</p>
    
    
    
    <p>4. Nous remplacerons tout produit fabriqué par Deluxe contenant des erreurs d'impression causées par Deluxe ou présentant un défaut de fabrication ou de matériaux, ou nous vous offrirons un remboursement si un chèque de remplacement ne vous donne pas satisfaction. Bien que Deluxe apporte le plus grand soin dans la fabrication de ses produits, il est commercialement impossible de détecter toutes les erreurs et imperfections. Par conséquent, aucune autre garantie n'est offerte, et tous les énoncés, échantillons ou modèles réalisés ou affichés le sont à titre indicatif seulement. LA GARANTIE CI-DESSUS REMPLACE EXPRESSÉMENT TOUTES LES AUTRES GARANTIES ET ASSERTIONS, EXPLICITES OU IMPLICITES, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D'ADAPTATION À UN USAGE PARTICULIER ET D'ABSENCE DE CONTREFAÇON. DELUXE NE SERA EN AUCUN CAS RESPONSABLE DE DOMMAGES DIRECTS, INDIRECTS, SPÉCIAUX, ACCESSOIRES, CORRÉLATIFS OU AUTRES DE TOUTE NATURE OU DESCRIPTION RÉSULTANT DE TOUTE UTILISATION OU MAUVAIS USAGE DE TOUT PRODUIT OU DE TOUTE ACTION OU OMISSION PAR DELUXE, NI DELUXE NE SERA RESPONSABLE DE TOUTE RUPTURE DE GARANTIE (OU DE TOUTE AUTRE OBLIGATION LIANT DELUXE) D'UN MONTANT SUPÉRIEUR AU PRIX D'ACHAT DU PRODUIT EFFECTIVEMENT PAYÉ À DELUXE.</p>
    
    
    
     
    
    
    <p>D. Accord d'arbitrage obligatoire, renonciation aux recours collectifs et renonciation aux procès devant jury</p>
    
    
    
    <p>1. Applicabilité de l'accord d'arbitrage. Vous et Deluxe acceptez que toutes les réclamations et tous les litiges liés de quelque manière que ce soit à l'utilisation de ce Site, ou aux produits ou services vendus par Deluxe ou distribués par Deluxe ou par l'entremise de Deluxe, seront résolus par arbitrage individuel devant l'American Arbitration Association («AAA») en vertu de la Loi fédérale sur l'arbitrage («FAA»), sauf que vous et Deluxe n'êtes pas tenus d'arbitrer les différends devant les cours des petites créances, comme indiqué au paragraphe (8.) ci-dessous. Pour être clair : l'expression «toutes les réclamations et tous les litiges» englobe également les réclamations et les litiges survenus entre vous et Deluxe avant la date d'entrée en vigueur des présentes Modalités.</p>
    
    
    <p>2. Renonciation aux procès devant jury. Vous et Deluxe renoncez à tout droit constitutionnel et/ou statutaire à un procès devant un juge ou un jury.</p>
    
    
    <p>3. Renonciation au recours collectif. Vous et Deluxe acceptez que tous les arbitrages se déroulent de façon individuelle et renoncez à tout droit d'introduire des réclamations dans le cadre d'un recours collectif (soit en tant que demandeur désigné ou membre du groupe), et dans le cadre de toute autre procédure pour laquelle quelqu'un agit à titre de représentant de quelque façon que ce soit.</p>
    
    
    <p>4. Pouvoir de l'arbitre. L'arbitre, et non tout tribunal ou organisme fédéral, provincial ou local, aura le pouvoir exclusif de résoudre tous les litiges découlant de ou liés à l'interprétation, l'applicabilité, ou la constitution du présent Accord, y compris, mais sans s'y limiter, toute réclamation à l'effet que la totalité ou une partie du présent Accord est nul ou susceptible d'annulation. Cependant, un arbitre peut accorder de manière individuelle les mêmes dommages-intérêts et réparations qu'un tribunal (y compris les mesures de redressement injonctives et déclaratoires ou les dommages statutaires). La décision de l'arbitre sera définitive, à l'exception d'un droit de révision limité en vertu de la FAA.</p>
    
    
    <p>5. Procédure d'arbitrage. L'une ou l'autre des parties peut entamer des procédures d'arbitrage auprès de l'AAA conformément à ses Règles d'arbitrage pour consommateurs («Règles AAA»), telles que modifiées par le présent accord d'arbitrage. On peut prendre connaissance des règles de l'AAA sur le site Web de l'AAA www.adr.org, ou en appelant l'AAA au (800) 778-7879. Vous pouvez choisir que l'arbitrage soit tenu par téléphone, en fonction de soumissions écrites, ou en personne dans le comté où vous demeurez ou à un autre endroit convenu d'un commun accord.</p>
    
    
    <p>6. Frais d'arbitrage et paiements. Le paiement de tous les frais de dépôt, d'administration et d'arbitrage sera régi par les règles applicables de l'AAA, mais Deluxe vous remboursera ces frais jusqu'à concurrence de 25 000 $ (bien que, en aucun cas, Deluxe ne paiera les honoraires d'avocat ou les frais que vous pourriez engager). Deluxe renonce également à son propre droit de demander le remboursement d'honoraires et frais d'avocat dans le cadre de l'arbitrage.</p>
    
    
    <p>7. Droit de retrait. Vous pouvez vous retirer de cet accord d'arbitrage en avisant Deluxe par écrit au plus tard 30 jours après avoir été assujetti pour la première fois à cet accord d'arbitrage. Votre avis doit inclure votre nom et votre adresse et une déclaration sans équivoque que vous souhaitez vous retirer de cet accord d'arbitrage. Vous devez soit poster votre avis de retrait à cette adresse : Deluxe Financial Services, LLC, ATTN : Privacy Program Office, 3660 Victoria Street North, Shoreview, MN 55126, soit envoyer l'avis de retrait par courriel à privacyprogramoffice@deluxe.com. Vous pouvez également refuser toute modification que nous pourrions apporter à cet accord d'arbitrage dans les versions ultérieures de ces Modalités en envoyant un avis à Deluxe dans les 30 jours suivant votre constatation de la modification. Si vous le faites, la version la plus récente de l'accord d'arbitrage auquel vous avez consenti avant la modification que vous avez refusée s'appliquera.</p>
    
    
    <p>8. Cour des petites créances. Nonobstant ce qui précède, vous ou Deluxe pouvez intenter une action individuelle devant la cour des petites créances de votre comté de résidence ou à Shoreview, Minnesota, si vous répondez aux exigences de cette cour.</p>
    
    
    <p>9. Divisibilité. Si la renonciation au recours collectif est jugée illégale ou inapplicable en regard de la totalité ou d'une partie d'un litige, alors ces parties du litige ne feront pas l'objet d'arbitrage et se rendront devant les tribunaux, le reste du litige étant réglé par arbitrage.</p>
    
    
    <p>10. Survivance de l'accord d'arbitrage. Cet accord d'arbitrage demeurera en vigueur après la cessation de votre relation avec Deluxe.</p>
    </div></main>`
  },
  {
    url: "privacy-policy",
    content: `
<main>
   <div class="static-container">
    
    <p>Politique de confidentialité de Deluxe Canada</p>
    
    <p>Date d’entrée en vigueur: 9/20/21</p>
    <p>Cette politique de confidentialité s'applique au site Web ou au service détenu et exploité par Deluxe Canada et ses filiales et sociétés affiliées (appelées ici «Deluxe», «nous» ou «notre») à partir duquel elle a été consultée. Les informations suivantes vous aideront à comprendre comment nous recueillons, utilisons, partageons et protégeons vos renseignements personnels. Ce document traite également de vos choix concernant l'utilisation, l'accès et la correction de vos renseignements personnels.</p>
    
    <p>Cueillette et utilisation des renseignements</p>
    <p>Deluxe est un fournisseur de produits et services aux entreprises canadiennes, et afin de vous fournir les produits ou services que vous avez demandés, Deluxe doit recueillir des renseignements personnels. Chez Deluxe, nous voulons vous donner le plus de contrôle possible sur vos renseignements personnels.</p>
     
    <p>Le type de renseignements que nous recueillons, utilisons ou partageons peut dépendre de la nature du produit ou du service qui vous intéresse chez nous. Nous pouvons parfois vous demander de fournir volontairement des renseignements d'identification qui vous serviront à recevoir de la correspondance, vous inscrire sur ce site Web, faire des achats ou participer en ligne à des sondages, des forums, des blogues, des séances de clavardage, des études de marché ou des concours ou tirages. Si vous choisissez de participer, nous pouvons vous demander de fournir des renseignements commerciaux comme votre nom, votre adresse postale, votre numéro de téléphone, les informations de votre institution financière, vos informations de carte de crédit ou votre adresse courriel.Dans certaines sections de notre site Web, nous pouvons recueillir des renseignements selon vos instructions, comme des renseignements sur les employés que vous ajoutez à votre compte d'affaires et des coordonnées actualisées. Nous n'utiliserons ces renseignements que pour la raison particulière pour laquelle ils sont fournis. Lorsque vous nous transmettez des renseignements commerciaux, vous comprenez et acceptez que nous pouvons accéder à ces renseignements et les stocker et les utiliser pour fournir les services demandés. Nous nous engageons à préserver et à protéger vos renseignements commerciaux et les renseignements personnels de vos employés. </p>
    
    <p>Nous utilisons les renseignements recueillis à votre sujet ou fournis selon vos instructions pour remplir vos commandes et enrichir votre expérience d'achat et la rendre plus personnelle. Nous pouvons également utiliser vos renseignements pour communiquer avec vous au sujet de produits, de services et de promotions futures. Pour valider les commandes et prévenir la fraude, nous pouvons échanger des renseignements sur nos clients à l'interne et auprès de nos sociétés affiliées. À l'aide des renseignements que vous nous fournissez et qui vous identifient personnellement, nous pouvons vous envoyer un courriel de bienvenue pour confirmer votre nom d'utilisateur et votre mot de passe. Nous communiquerons également avec vous pour répondre à vos demandes, vous fournir les services que vous demandez, et pour gérer votre compte. Nous communiquerons avec vous par courriel ou téléphone, selon votre préférence. Nous pouvons vous envoyer des communiqués de service à de rares occasions lorsque cela est nécessaire. Ces communications ne sont pas de nature promotionnelle et, en règle générale, vous ne pouvez pas vous en désinscrire. Si vous ne souhaitez pas les recevoir, vous avez la possibilité de désactiver votre compte. </p>
    
    <p>Pour les témoignages, nous obtenons l'autorisation des clients avant de publier leur nom avec leur témoignage. Nous ne sommes pas responsables des renseignements personnels identifiables que vous choisissez de transmettre par le biais de témoignages. Les renseignements que vous fournissez seront protégés conformément aux normes de l'industrie en matière de sécurité et de confidentialité.Partage de vos renseignements et vos choix

    <table>
      <thead>
        <tr>
          <th>Raisons pour lesquelles nous pouvons partager vos renseignements</th>
          <th>Deluxe les partage?</th>
          <th>Pouvez-vous limiter ce partage?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Pour nos besoins commerciaux quotidiens : facturation, traitement des transactions et exécution des commandes</td>
          <td>Oui</td>
          <td>Non</td>
        </tr>
        <tr>
          <td>À des fins de marketing : pour vous offrir des produits et services qui feront croître votre entreprise</td>
          <td>Oui</td>
          <td>Oui</td>
        </tr>
        <tr>
          <td>Pour que nos sociétés affiliées puissent vous faire des offres</td>
          <td>Oui</td>
          <td>Oui</td>
        </tr>
        <tr>
          <td>Pour que des tiers puissent vous faire des offres en notre nom</td>
          <td>Non</td>
          <td>N-D</td>
        </tr>
      </tbody>
    </table>
   	
    <p>Les noms et autres renseignements personnels que nous recevons par l'intermédiaire d'une institution financière ne sont pas partagés avec des tiers, sauf dans le but de satisfaire à nos exigences contractuelles avec des institutions financières ou par ailleurs tel que permis par la loi. </p>
    
    <p>Dans certains cas, nous pouvons faire appel à des fournisseurs de services tiers pour nous permettre de vous procurer du service client ou vous procurer le service que vous demandez, et vous acceptez qu'à ces fins nous puissions leur communiquer des renseignements à votre sujet. Lorsque nous faisons appel à des fournisseurs tiers, ils ne reçoivent que les renseignements nécessaires à la prestation de ces services, comme l'exécution des commandes, le traitement des paiements ou honorer des services d'abonnement. Ces fournisseurs tiers sont tenus de respecter leurs obligations contractuelles lorsqu'ils accomplissent des tâches précises pour nous et il leur est interdit d'utiliser vos renseignements à d'autres fins. </p>
    
    <p>Nous vous offrons la possibilité de refuser de recevoir de la publicité non sollicitée à la suite de votre interaction avec nous. Si vous souhaitez ne plus recevoir d'offres spéciales ou de messages promotionnels de notre part, il vous suffit de modifier votre profil de compte ou suivre les instructions de désabonnement incluses dans chaque communication. Vous pouvez également signifier votre refus en contactant le Service à la clientèle, comme décrit ci-dessous. </p>
    
    <p>Il peut arriver que nous soyons tenus de divulguer des renseignements personnels en réponse à des demandes légitimes d'instances publiques, y compris pour nous conformer aux exigences de l'application de la loi. Nous pouvons également divulguer vos renseignements personnels conformément à la loi, par exemple pour nous conformer à une assignation à comparaître ou à d'autres processus judiciaires, lorsque nous croyons de bonne foi que cette divulgation est nécessaire pour protéger nos droits, garantir votre sécurité ou celle d'autrui, enquêter sur une fraude, ou obtempérer à une demande du gouvernement. </p>
    
    <p>Nous nous réservons le droit de divulguer des renseignements vous concernant lorsque la loi l'exige et lorsque nous croyons que cette divulgation est nécessaire pour protéger nos droits et/ou nous conformer à un processus judiciaire, une ordonnance d'un tribunal ou une procédure judiciaire concernant notre entreprise. <a href="https://www.deluxe.com/policy/subpoena-policy">Notre politique régissant les assignations à comparaître peut être consultée</a>. Si nous prenons part à une fusion, une acquisition, une dissolution ou une vente complète ou partielle d'actifs, nous nous réservons le droit de transférer vos renseignements personnels. Un courriel ou un avis bien visible sur notre site Web vous informera de tout changement de propriétaire, de l'utilisation de vos renseignements personnels et des choix que vous pourriez vouloir faire concernant vos renseignements personnels.Sécurité
    Nous nous engageons à protéger vos renseignements personnels et commerciaux. Les mesures de sécurité mises en place sur ce site Web ont pour but de protéger les renseignements que nous détenons contre la perte, l'utilisation abusive et l'altération. Nous proposons des mesures de sécurité conformes aux normes de l'industrie par le biais de votre navigateur Web. Lorsque vous êtes sur ce site Web, les données sensibles que vous fournissez, comme les informations de carte de crédit et financières, sont cryptées avec la technologie Secure Socket Layer (SSL) et brouillées en cours de route, puis décodées une fois parvenues jusqu'à nous. N'oubliez pas que les courriels sont différents des transmissions cryptées ci-dessus, et ne sont pas sécurisés. Par conséquent, nous vous demandons de ne pas nous envoyer de données sensibles comme des numéros de carte de crédit ou de compte par l'entremise de messages électroniques non sécurisés. </p>
    
    <p>Les renseignements que vous fournissez seront protégés selon des normes strictes de sécurité et de confidentialité. Nous préconisons une utilisation responsable des renseignements que vous choisissez de fournir. Nous exigeons contractuellement de nos vendeurs, consultants, fournisseurs et sous-traitants qu'ils aient instauré des mesures de sécurité appropriées et respectent toutes les exigences contractuelles concernant la sécurité et la cueillette, l'utilisation et le partage des renseignements de nos clients. Ils sont tenus de respecter les modalités de leur contrat lorsqu'ils effectuent des tâches pour nous et il leur est interdit d'utiliser vos renseignements personnels à d'autres fins. </p>
    
    <p>Nous pouvons conserver vos renseignements aussi longtemps que votre compte est actif ou au besoin pour vous fournir des services, nous conformer à nos obligations légales, résoudre des litiges et faire respecter nos accords.Suivi de l'activité sur notre site Web</p>
    <p>Afin d'optimiser votre visite sur notre site Web, nous suivons certaines activités des utilisateurs. Cependant, sachez que la technologie de suivi que nous utilisons n'identifie aucun renseignement personnel à votre sujet. Cette technologie ne peut pas extraire de données de votre disque dur, propager des virus informatiques ou s'emparer de vos renseignements personnels comme votre nom, votre adresse, votre numéro de téléphone ou votre adresse de courriel. Nous ne savons qui vous êtes que si vous nous le dites.</p> 
    
    <p>Comme c'est le cas avec la plupart des sites Web, nous recueillons automatiquement certains renseignements lorsque des personnes naviguent sur notre site et les emmagasinons dans des historiques. Parmi ces renseignements, on retrouve les adresses de protocole Internet (IP), le type de navigateur, le fournisseur de services Internet, les pages de renvoi et de sortie, les fichiers consultés sur notre site (pages HTML, diagrammes, etc.), le système d'exploitation, les marques temporelles et les données de parcours de navigation. Nous faisons appel à un service de suivi qui utilise la technologie pour suivre de façon globale ces renseignements non personnels sur les visiteurs de notre site. </p>
    
    <p>Nos partenaires et nous utilisons des témoins (cookies) ou des technologies similaires pour améliorer votre expérience d'achat, analyser les tendances, administrer le site, suivre les déplacements des utilisateurs sur le site et recueillir des informations démographiques sur notre base d'utilisateurs dans son ensemble. Les témoins consistent en de fragments d'information stockés par votre navigateur Web sur le disque dur de votre ordinateur. Un témoin peut renfermer des informations (comme un identifiant de session à utilisateur unique) qui sont utilisées pour suivre les pages des sites Web qu'un utilisateur a visités. Les informations contenues dans les témoins aident à mieux vous servir en conservant une trace de votre commande lors de vos achats sur ce site; cela nous aide également à améliorer la conception du site. Nous ne mettons pas d'informations sensibles dans vos témoins. Vous pouvez contrôler l'utilisation des témoins au niveau de votre navigateur, mais si vous choisissez de désactiver les témoins, votre utilisation de certaines fonctionnalités de notre site Web pourrait s'en trouver limitée.</p> 
    
    <p>Nous collaborons également avec des tiers pour afficher de la publicité sur notre site Web ou gérer notre publicité sur d'autres sites. Notre partenaire tiers peut utiliser des témoins ou des technologies similaires afin de vous proposer des publicités en fonction de vos activités de navigation et de vos intérêts.  <a href="https://www.youronlinechoices.eu/"> Si vous souhaitez ne pas recevoir de publicité axée sur vos intérêts</a>. Prenez note que vous continuerez à recevoir des publicités génériques.Visite de nos sites Web par des enfants
    Ce site Web est destiné à une utilisation par des adultes. Nous ne recueillons ni n'utilisons sciemment de renseignements sur des enfants âgés de moins de 13 ans. Si nous prenons connaissance que des renseignements personnels sur des enfants ont été recueillis, ils seront immédiatement supprimés.</p>
    
    <p>Autres sites Web liés à ce site</p>
    <p>Ce site Web comporte des liens menant vers d'autres sites qui peuvent ou non être en notre possession ou sous notre contrôle. Nous vous encourageons à être vigilants lorsque vous quittez ce site pour aller sur d'autres sites, et à lire les politiques de confidentialité et les conditions d'utilisation applicables publiées sur chacun de ces sites Web qui recueillent des renseignements personnels. La présente politique de confidentialité ne s'applique qu'aux renseignements recueillis sur ce site.</p>
    
    <p>Modifications apportées à notre politique de confidentialité</p>
    <p>En utilisant ce site Web, vous acceptez la politique de confidentialité que nous y publions. Si vous n'acceptez pas cette politique de confidentialité, veuillez ne pas naviguer sur notre site. Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment; veuillez donc la consulter fréquemment. Votre utilisation continue de ce site Web après la publication de modifications apportées à cette politique signifie que vous acceptez ces modifications. </p>
    
    <p>Nous pouvons modifier ou actualiser notre politique de confidentialité à tout moment. Toute modification à la politique de confidentialité et de sécurité sera publiée sur ce site Web et à tout autre endroit que nous jugeons approprié afin de vous maintenir au courant des renseignements que nous recueillons, ainsi que de la manière dont nous les utilisons et les protégeons. Si nous apportons des modifications importantes à cette politique, nous vous en informerons avant leur entrée en vigueur par courriel ou au moyen d'un avis affiché sur notre page Web. </p>
    
    <p>Périodiquement, nos opérations et pratiques commerciales sont examinées pour en vérifier la conformité avec les politiques et procédures de notre entreprise régissant la confidentialité des renseignements. Ces analyses sont effectuées par le personnel interne qui relève directement du conseil d'administration, par des cabinets externes de vérification et de comptabilité et par des organismes de réglementation gouvernementaux. Ces auto-évaluations et analyses comprennent des examens des contrôles internes et mécanismes de protection décrits dans notre politique de confidentialité. </p>
    
    <p>Notre entreprise est accréditée par le Bureau d'éthique commerciale (BBB) et respecte les normes BBBOnLine.Correction, mise à jour, accès et renonciation:</p>
    <p>Nous nous engageons à faire en sorte que les renseignements sur nos clients que nous obtenons et utilisons sont exacts. Vous pouvez accéder à vos renseignements personnels, les corriger ou demander leur suppression en mettant à jour votre profil en ligne ou en communiquant avec nous par l'une des méthodes indiquées ci-dessous. Nous répondrons à votre demande dans un délai raisonnable, et nos représentants de service sont formés pour répondre aux questions et vous donner un accès raisonnable à vos renseignements personnels se trouvant dans notre base de données. </p>
    
    <p>Nous conserverons vos renseignements aussi longtemps que votre compte sera actif ou au besoin pour vous fournir des services, et nous utiliserons vos renseignements si nécessaire pour nous conformer à nos obligations légales, résoudre des litiges et faire respecter nos accords. Si vous souhaitez fermer votre compte ou nous demander de ne plus utiliser vos renseignements pour vous fournir des services, contactez-nous aux coordonnées ci-dessous.Pour nous contacter
    Si vous avez des questions concernant cette politique de confidentialité, les pratiques de ce site Web ou vos interactions avec ce site Web, n'hésitez pas à nous contacter : 
    Privacy Program Office</br>
    à l'attention de: Privacy Compliance Advisor </br>
    3680 Victoria Street N. </br>
    Shoreview, MN 55126 </br>
    Téléphone : 1-833-341-1813 </br>
    Télécopieur : 1-800-336-1112 </br>
    Courriel : privacyprogramoffice@deluxe.comSi vous avez des préoccupations concernant la confidentialité ou l'utilisation des données que nous n'avons pas abordées de manière satisfaisante, <a href="https://feedback-form.truste.com/watchdog/request">communiquez avec notre fournisseur de règlement de différends situé aux États-Unis (gratuitement)</a>. </p>
      </div> 
      `
  },
  {
    url: "accessibility",
    content: `<div class="static-container accessibility whitebox content-padding rounded clearfix">


<main>
  <div class="row-fluid">


<h1>Politique d'accessibilité du site Web</h1>
<hr aria-hidden="true">

<p>
Deluxe s'engage à faciliter l'accès aux biens, services et privilèges offerts sur le site Web cheques.deluxe.ca pour les personnes ayant un handicap, et contribue aux efforts constants déployés pour améliorer l'accessibilité au site cheques.deluxe.ca. N'hésitez pas à nous contacter si vous avez des questions ou des préoccupations précises à propos de l'accessibilité à ce site, ou si vous avez besoin d'aide pour l'utiliser. Envoyez un courriel à accessibility@deluxe.com en mentionnant la page Web pour laquelle vous avez des questions, souhaitez soumettre un commentaire ou avez besoin d'aide.
</p>

        
  </div>
</main>
`
  }
];

export default content;
