import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import close_white from "../../../assets/icons/close_white.svg";
import Backdrop from "./Backdrop";

import "./styles/Modal.css";

const ModalOverlay = props => {
  const content = (
    <div
      className={
        props.noClass ? `${props.className}` : `modal ${props.className}`
      }
      style={props.style}
    >
      {props.noHeader ? null : (
        <header className={`modal__header ${props.headerClass}`}>
          <h2>{props.header}</h2>
          <i onClick={props.onCancel}>
            <img src={close_white} alt="Icône de fermeture"></img>
          </i>
        </header>
      )}

      {props.children}

      {props.noFooter ? null : (
        <footer
          className={
            props.noFooterClass
              ? `${props.footerClass}`
              : `modal__footer ${props.footerClass}`
          }
        >
          {props.footer}
        </footer>
      )}
    </div>
  );
  return content;
};

const Modal = props => {
  return ReactDOM.createPortal(
    <React.Fragment>
      {props.show && (
        <Backdrop onClick={props.onCancel} dropDownList={props.dropDownList} />
      )}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>,
    document.getElementById("modal-hook")
  );
};

export default Modal;
