import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  setPersonalizedQuestionsProgressAction,
  displayLoadingForReviewProcessAction
} from "../../../../redux/actions/productAction";
import Modal from "../../../../shared/components/UIElements/Modal";
import { I18nContext } from "../../../../i18n/index";
import { FormattedNumber } from "react-intl";
import "./Styles/AddToCartBox.css";
import Loading from "../../../AC-Loading/Loading";
import Select from "../ChequeCustomization/components/Select";

import { SECTION_BUTTONS } from "../ChequeCustomization/data/section-buttons";

import Attributes from "../Attributes/Attributes";
import ChequeCustomization from "../ChequeCustomization/ChequeCustomization";
import SectionButtons from "../ChequeCustomization/SectionButtons";

import GrandTotalAndAddToCartButton from "./Components/GrandTotalAndAddToCartButton/GrandTotalAndAddToCartButton";

const WIGGLE_TIMER = 2500;

const AddToCartBox = ({ handleEnquiryModalOpenClicked }) => {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = useContext(I18nContext);

  const [activeSection, setActiveSection] = useState(
    SECTION_BUTTONS && SECTION_BUTTONS[0]
  );

  const [displayPricingTable, setDisplayPricingTable] = useState(true);

  const [firstDistId, setFirstDistId] = useState(0);
  const [price, setPrice] = useState(null);
  const [distributorId, setDistributorId] = useState(null);
  const [packagePrices, setPackagePrices] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({
    key: "selected quantity",
    value: -1,
    noPackage: false
  });

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const [productQuantityType, setProductQuantityType] = useState(null);

  const [numberOfItems, setNumberOfItems] = useState(1);

  // const [shouldWiggle, setShouldWiggle] = useState(false);
  const [perUnitState, setPerUnitState] = useState("");
  const [inStock, setInStock] = useState(true);
  const initialValue = useRef(true);

  const personalizedQuestionInputsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.inputs,
    shallowEqual
  );

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const personalizedQuestionsValidationsState = useSelector(
    state => state.productReducer.personalizedQuestionsValidations,
    shallowEqual
  );

  const supplierInfoState = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const isProductDetailsLoading = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const requestingAddToCartState = useSelector(
    state => state.productReducer.requestingAddToCart,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const attributesState = useSelector(
    state => state.productReducer.itemDetail.attributes,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );
  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const skusState = useSelector(
    state => state.productReducer.itemDetail.skus,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const [nextButtonClickedOnce, setNextButtonClickedOnce] = useState(false);

  useEffect(() => {
    if (itemIdState) {
      setNextButtonClickedOnce(false);
    }
  }, [itemIdState]);

  const personalizedItemState = useSelector(
    state => state.productReducer.itemDetail.personalized,
    shallowEqual
  );

  const packagePriceEnabledState = useSelector(
    state => state.productReducer.itemDetail.packagePriceEnabled,
    shallowEqual
  );

  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const productInitialProperties = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  const bundleItemsProgressState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.bundleItemsProgress,
    shallowEqual
  );

  const bundleItemsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.bundleItems,
    shallowEqual
  );

  const productInitialBundleState = useSelector(
    state => state.productReducer.productInitial.bundle,
    shallowEqual
  );
  const productHaveBundleItems = useMemo(() => {
    if (productInitialBundleState) {
      if (
        productInitialBundleState &&
        Array.isArray(productInitialBundleState) &&
        productInitialBundleState.length > 0
      ) {
        return true;
      } else return false;
    }
    return undefined;
  }, [productInitialBundleState]);

  const currentItemIsBundleItemState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress
        .currentItemIsBundleItem,
    shallowEqual
  );

  const biidState = useSelector(
    state => state.productReducer.biid,
    shallowEqual
  );

  const biidReviewState = useSelector(
    state => state.productReducer.biidReview,
    shallowEqual
  );

  const displayLoadingForReviewProcessState = useSelector(
    state => state.productReducer.displayLoadingForReviewProcess,
    shallowEqual
  );

  const basketState = useSelector(
    state => state.sessionReducer.basket,
    shallowEqual
  );

  const selectedPackageForKitItemState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress
        .selectedPackageForKitItem,
    shallowEqual
  );

  const addToCartBtnRef = useRef(null);

  useEffect(() => {
    if (
      itemIdState &&
      biidReviewState &&
      productHaveBundleItems === false &&
      personalizedItemState
    ) {
      dispatch(displayLoadingForReviewProcessAction(true));
    }
  }, [
    itemIdState,
    biidReviewState,
    productHaveBundleItems,
    personalizedItemState
  ]);

  useEffect(() => {
    if (
      nextButtonClickedOnce === false &&
      productHaveBundleItems === false &&
      !isProductDetailsLoading &&
      addToCartBtnRef &&
      addToCartBtnRef.current &&
      biidReviewState &&
      personalizedItemState &&
      ((selectedProductAttributesState &&
        selectedProductAttributesState[mainItemIdState || itemIdState]) ||
        (attributesState && attributesState.length === 0))
    ) {
      setNextButtonClickedOnce(true);
      addToCartBtnRef.current.click();
      setTimeout(() => {
        dispatch(displayLoadingForReviewProcessAction(false));
      }, 750);
    }
  }, [
    isProductDetailsLoading,
    selectedProductAttributesState,
    personalizedQuestionsProgressState.bundleItemParentId,
    personalizedItemState,
    productInitialBundleState
  ]);

  useEffect(() => {
    if (isProductDetailsLoading) {
      setPerUnitState(null);
    } else if (selectedPackageForKitItemState) {
      setSelectedPackage(selectedPackageForKitItemState);
    } else {
      let prices = itemDetailState && itemDetailState.prices;
      let distributorId = prices && prices.distributorId;

      let price = prices && prices[0] && prices[0].price_1;
      let tempPackagePrices = [];

      //set package prices
      if (prices && prices.length > 0) {
        //sort  prices based on priority

        const numberItemCode = itemCode => {
          if (itemCode.includes("-")) {
            return parseInt(itemCode.split("-")[1]);
          }
          return 0;
        };
        let sortedPrices = prices.sort((a, b) => {
          const aItemCode = numberItemCode(a.itemcode);
          const bItemCode = numberItemCode(b.itemcode);
          return aItemCode - bItemCode;
        });

        const atleastOneDashedItemCodeExists = sortedPrices.some(p =>
          p.itemcode.includes("-")
        );
        tempPackagePrices = sortedPrices.reduce((a, c) => {
          if (atleastOneDashedItemCodeExists) {
            if (
              c.packagePrices.length > 0 &&
              c.itemcode.includes("-") &&
              !c.priceLabel
            ) {
              const itemCodeNumber = numberItemCode(c.itemcode);
              c.priceLabel = `${itemCodeNumber} ${
                itemCodeNumber > 1 ? "Copies" : "Copy"
              }`;
              a.push({ prices: c.packagePrices, label: c.priceLabel });
            } else {
              if (c.packagePrices.length > 0)
                a.push({ prices: c.packagePrices, label: c.priceLabel });
            }
          } else {
            if (c.packagePrices.length > 0)
              a.push({ prices: c.packagePrices, label: c.priceLabel });
          }

          return a;
        }, []);
      }

      if (distributorId) setDistributorId(distributorId);
      if (tempPackagePrices && tempPackagePrices.length > 0) {
        setPackagePrices(tempPackagePrices);
        setPrice(tempPackagePrices[0].prices[0].price);
        setSelectedPackage({
          ...selectedPackage,
          value: tempPackagePrices[0].prices[0].price,
          text: tempPackagePrices[0].prices[0].quantity
        });
      } else {
        setPrice(price);
        setSelectedPackage({
          ...selectedPackage,
          value: null,
          text: null,
          noPackage: true
        });
      }
      /*  if (tempPackagePrices) {
          setPackagePrices(tempPackagePrices);
        if (
          tempPackagePrices &&
          tempPackagePrices[0] &&
          tempPackagePrices[0].prices &&
          tempPackagePrices[0].prices.length > 1
        ) {
          setPrice(price);

          setSelectedPackage({
            ...selectedPackage,
            value: tempPackagePrices[0].prices[0].price,
            text: tempPackagePrices[0].prices[0].quantity
          });
        }
      } */
    }
  }, [isProductDetailsLoading]);

  useEffect(() => {
    if (
      Object.keys(priceState).length > 0 &&
      supplierInfoState &&
      supplierInfoState[0] &&
      supplierInfoState[0].distributorOrder
    ) {
      let firstDistId = supplierInfoState[0].distributorOrder[0].distid;
      setFirstDistId(firstDistId);
      setPrice(
        priceState.prices.find(inv => inv.distributorId == firstDistId).price_1
      );
      // determine stock status
      setInStock(
        priceState.invs[0].instock > 0 ||
          (mainItemIdState === 0 && skusState.length > 0)
      );
    }
  }, [priceState, supplierInfoState]);

  /*   useEffect(() => {
    let timer = null;
    if (numberOfItems) {
      if (initialValue.current) {
        initialValue.current = false;
        return;
      }

      timer = setTimeout(() => {
        setShouldWiggle(true);
      }, WIGGLE_TIMER);
    }
    return () => {
      setShouldWiggle(false);
      clearTimeout(timer);
    };
  }, [numberOfItems]); */

  useEffect(() => {
    if (productInitialProperties && productInitialProperties.length > 0) {
      let perUnitProp = productInitialProperties.find(prop =>
        ["Per Unit", "Per-Unit"].includes(Object.keys(prop)[0])
      );

      if (perUnitProp) setPerUnitState(Object.values(perUnitProp)[0]);

      const quantityTypeProp = productInitialProperties.find(prop =>
        Object.keys(prop).includes("product_quantity_type")
      );

      if (quantityTypeProp)
        setProductQuantityType(quantityTypeProp.product_quantity_type);
    }
  }, [productInitialProperties]);

  const handleSetQuantityInput = e => {
    const value = Number(e.target.value);

    if (value === 0) setNumberOfItems("");
    else if (value > 0 && String(value).length <= 9)
      setNumberOfItems(Number(e.target.value));
  };

  const handleOnInputBlur = e => {
    const value = e.target.value;

    if (value === "") setNumberOfItems(1);
  };

  const renderQuantityInput = () => {
    return (
      selectedPackage.noPackage && (
        <div id="buyBoxQtyBox">
          <div className="qtyControlsBox">
            <div className="qtyControlsBtns">
              <div
                className="qtyControlsPlus no-select"
                onClick={() => setNumberOfItems(numberOfItems + 1)}
                style={{ cursor: "pointer" }}
              >
                <span>+</span>
              </div>
              <div
                className="qtyControlsMinus no-select"
                onClick={() => {
                  if (numberOfItems - 1 > 0) {
                    setNumberOfItems(numberOfItems - 1);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <span>-</span>
              </div>
            </div>
            <input
              size={String(numberOfItems).length || 1}
              className="qtyControlsInput"
              type="text"
              value={numberOfItems}
              onChange={e => {
                handleSetQuantityInput(e);
              }}
              onBlur={handleOnInputBlur}
            />
            <div className="clearfix"></div>
          </div>
        </div>
      )
    );
  };

  const renderPerUnitText = () => {
    if (perUnitState) {
      return (
        <small className="add-to-cart-box--per-unit-text">{perUnitState}</small>
      );
    } else return null;
  };

  const renderStartingFrom = () => {
    if (
      !currentItemIsBundleItemState &&
      packagePrices &&
      packagePrices.length > 0 &&
      packagePrices[0] &&
      packagePrices[0].prices &&
      packagePrices[0].prices[0] &&
      packagePrices[0].prices[0].price
    ) {
      let firstPackageOption = packagePrices[0].prices;

      return (
        <div className="first-package-price-wrapper">
          <span className="first-package-price-starting-from">
            À Partir de:
          </span>
          &nbsp;
          <FormattedNumber
            /* currencyDisplay="narrowSymbol" */
            value={firstPackageOption[0].price}
            style="currency"
            currency={"USD"}
            children={value => (
              <span className="first-package-price">
                {value.replace("US", "")}
              </span>
            )}
          />
          <span className="first-package-unit">{` / ${
            firstPackageOption[0].quantity
          } ${
            productQuantityType || firstPackageOption[0].quantity === 1
              ? "Produit"
              : "Produits"
          }`}</span>
        </div>
      );
    } else return null;
  };

  const renderPricingTable = () => {
    if (
      !currentItemIsBundleItemState &&
      packagePrices &&
      packagePrices.length > 0 &&
      packagePrices[0] &&
      packagePrices[0].prices &&
      packagePrices[0].prices[0] &&
      packagePrices[0].prices[0].price
    ) {
      console.info("Packageprices2", packagePrices);
      const fistPricePackageLabelIncludesCopyWord = [
        "copie",
        "copies",
        "exemplaire",
        "exemplaires"
      ].some(
        t =>
          packagePrices[0] &&
          packagePrices[0].label &&
          packagePrices[0].label.toLowerCase().includes(t)
      );

      const indexOfTheLongestPackagePricesArray = packagePrices.reduce(
        (a, c, i) => {
          if (c.prices && c.prices.length > a.size) {
            return { index: i, size: c.prices.length };
          }
          return a;
        },
        { index: 0, size: 0 }
      );

      const bracketOfQuantity = packagePrices[
        indexOfTheLongestPackagePricesArray.index
      ].prices.reduce((a, c) => {
        a.push(c.quantity);
        return a;
      }, []);

      console.info(
        "indexOfTheLongestPackagePricesArray",
        indexOfTheLongestPackagePricesArray,
        packagePrices,
        fistPricePackageLabelIncludesCopyWord
      );
      return (
        <div className="pricing-table-wrapper">
          <div className="pricing-table-header">
            <p>Prix</p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => setDisplayPricingTable(!displayPricingTable)}
            >
              {displayPricingTable ? `Casher` : `Montre`}
            </p>
          </div>
          <div
            className="pricing-table scroll-bar-thin-style--horizontal"
            style={{ height: displayPricingTable ? "" : "0px" }}
          >
            <table
              tabIndex={
                personalizedQuestionsProgressState.progress === 0 ? "0" : "-1"
              }
            >
              <thead>
                <tr>
                  <th>
                    {
                      /* fistPricePackageLabelIncludesCopyWord
                      ? "Copies"
                      : */ "Quantité"
                    }
                  </th>
                  {packagePrices[
                    indexOfTheLongestPackagePricesArray.index
                  ].prices.map(p => (
                    <th key={p.quantity}>{p.quantity}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {packagePrices.map((p, i) => (
                  <tr key={i}>
                    <td>
                      {fistPricePackageLabelIncludesCopyWord ? p.label : "Prix"}
                    </td>
                    {bracketOfQuantity.map(q => {
                      const foundPrice = p.prices.find(pr => pr.quantity === q);
                      if (foundPrice) {
                        return (
                          <td key={foundPrice.price}>
                            <FormattedNumber
                              currency={"USD"}
                              /*   currencyDisplay="narrowSymbol" */
                              value={foundPrice.price.toFixed(2)}
                              style="currency"
                              children={value => value.replace("US", "")}
                            ></FormattedNumber>
                          </td>
                        );
                      } else {
                        return <td key={i}>N/A</td>;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else return null;
  };

  const renderQuantitySelector = () => {
    if (packagePrices && packagePrices.length > 0) {
      const unitTextHandler = qty => {
        return qty > 1 ? "Produits" : "Produit";
      };

      let ddtext = null;
      let indexOfPackagePrice = 0;

      if (
        selectedProductAttributesState &&
        selectedProductAttributesState.hasOwnProperty(
          mainItemIdState || itemIdState
        )
      ) {
        let attrs =
          selectedProductAttributesState[mainItemIdState || itemIdState];

        let firstAttr = attrs && attrs[Object.keys(attrs)[0]];

        ddtext = firstAttr && firstAttr.ddtext;

        if (ddtext) {
          let tempIndex = packagePrices.findIndex(p =>
            p.label.toLowerCase().includes(ddtext.toLowerCase())
          );

          if (tempIndex !== -1) {
            indexOfPackagePrice = tempIndex;
          }
        }
      }

      let showBundleItemTitleWithQuantities = false;

      if (
        packagePrices[indexOfPackagePrice].prices.length > 1 &&
        bundleItemsState &&
        bundleItemsState.length > 1
      ) {
        showBundleItemTitleWithQuantities = true;
      }

      let options = packagePrices[indexOfPackagePrice].prices.reduce(
        (a, c, i) => {
          const unitText = unitTextHandler(c.quantity);

          let text = c.quantity;
          if (showBundleItemTitleWithQuantities) {
            text = bundleItemsState.reduce((a, bundleItem, idx, arr) => {
              a += `${Number(c.quantity) * bundleItem.qty} ${
                bundleItem.title
              } ${idx < arr.length - 1 ? "+ " : ""}`;
              return a;
            }, "");
          }
          a.push({
            value: c.price,
            text,
            label: i === 0 ? `${unitText} (Recommandé)` : `${unitText}`
          });

          return a;
        },
        []
      );

      let defaultValue = null;

      if (
        biidState &&
        basketState &&
        basketState.products &&
        basketState.products.length > 0
      ) {
        const foundBiidProduct = basketState.products.find(
          p => p.id === biidState
        );
        if (foundBiidProduct) {
          defaultValue = foundBiidProduct.qty;
        }
      }

      // check if bundleItem and set the quantity
      if (bundleItemsState && itemIdState) {
        const foundItemInsideBundleItems = bundleItemsState.find(bundleItem => {
          return bundleItem.id == itemIdState;
        });

        if (foundItemInsideBundleItems) {
          console.info("foundBundleItem", foundItemInsideBundleItems);
          defaultValue = foundItemInsideBundleItems.qty;
        }
      }
      return (
        <div
          style={{ marginTop: currentItemIsBundleItemState ? "2rem" : "" }}
          className={`quantity-selector-wrapper${
            packagePrices.length < 1 ? " no-table" : ""
          }`}
        >
          <Select
            disabled={currentItemIsBundleItemState}
            packagePrices={packagePrices}
            options={options}
            field={selectedPackage.key}
            state={selectedPackage}
            setState={setSelectedPackage}
            label="Sélectionner la quantité:"
            defaultValue={defaultValue}
            tabIndex={
              personalizedQuestionsProgressState.progress === 0 ? "0" : "-1"
            }
          />
        </div>
      );
    } else return null;
  };

  const renderPersonalizedQuestionsJsonIssueModal = () => {
    return (
      <Modal
        onCancel={() =>
          dispatch(
            setPersonalizedQuestionsProgressAction({
              jsonIssue: { occured: true, modalActive: false }
            })
          )
        }
        noFooter={true}
        show={personalizedQuestionsProgressState.jsonIssue.modalActive}
        header="A problem occured while adding the product to the cart."
      >
        <div className="main-category-modal-content">
          <p>
            Please contact us about the product you are trying to add to cart.
          </p>
        </div>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {displayLoadingForReviewProcessState && (
        <div className="review-process-loading-container">
          <div className="review-process-loading-wrapper">
            <h4>Loading the product to be reviewed...</h4>
            <Loading className="reviewProcessLoading" />
          </div>
        </div>
      )}
      {requestingAddToCartState ? (
        <Loading className="addToCartLoading" />
      ) : null}
      {biidReviewState && (
        <div className={`reviewInProgressWrapper`}>
          <h3>Révision en cours...</h3>
        </div>
      )}

      {bundleItemsState && bundleItemsState.length > 0 && !biidReviewState && (
        <div className={`reviewInProgressWrapper`}>
          <h3>Le montage du kit est en cours...</h3>
        </div>
      )}
      <div
        id="addToCardGrid"
        tabIndex={
          personalizedQuestionsProgressState.displayQuestions ? "-1" : ""
        }
      >
        {personalizedItemState &&
          !personalizedQuestionsProgressState.displayQuestions && (
            <SectionButtons
              {...{ activeSection, setActiveSection, quantityTabState: true }}
            />
          )}
        <div
          className="buyBox"
          tabIndex={
            personalizedQuestionsProgressState.progress === 0 ? "0" : "-1"
          }
        >
          <div className="buy-box-qty-price-container">
            <div className="buy-box-qty-wrapper">{renderQuantityInput()}</div>
            <div className="buy-box-price-wrapper">
              {renderStartingFrom()}

              <div id="buyBoxPrice">
                {/*   {isProductDetailsLoading ? (
                  <CatLoading className="add-to-cart-box--price-loading" />
                ) : (
                  <div
                    className={`unit-price-wrapper${
                      (packagePrices && packagePrices.length > 0) === false
                        ? " no-package-price"
                        : ""
                    }`}
                  >
                    <FormattedNumber
                      value={price}
                      style="currency"
                      currency={currency}
                      children={value => (
                        <span className="unit-price">{value}</span>
                      )}
                    />
                    {packagePrices && packagePrices.length > 0 && (
                      <span className="unit-price-unit">
                        /{productQuantityType || "Product"}
                      </span>
                    )}
                  </div>
                )} */}
              </div>
              {renderPricingTable()}
              <Attributes isBundleItem={currentItemIsBundleItemState} />

              {renderQuantitySelector()}
            </div>
            {renderPerUnitText()}
          </div>
          {!personalizedQuestionsProgressState.displayQuestions && (
            <GrandTotalAndAddToCartButton
              addToCartBtnRef={addToCartBtnRef}
              packagePrices={packagePrices}
              selectedPackage={selectedPackage}
              inStock={inStock}
              distributorId={distributorId}
              numberOfItems={numberOfItems}
            />
          )}
        </div>
        {renderPersonalizedQuestionsJsonIssueModal()}
        <ChequeCustomization
          {...{
            activeSection,
            setActiveSection,
            addToCartBtnRef,
            packagePrices,
            selectedPackage,
            inStock,
            distributorId,
            numberOfItems
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default AddToCartBox;
