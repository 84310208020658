import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";

export const handleSelectedInkColor = (
  selectedId,
  question,
  options,
  dispatch,
  progressState
) => {
  if (
    ["Couleur de la surimpression", "2nd Ink Colour"].includes(question) ===
    false
  )
    return;

  let selectedOption = options.find(opt => opt.detailID === selectedId);

  if (!selectedOption) {
    dispatch(
      setPersonalizedQuestionsProgressAction({
        font: { ...progressState.font, [question]: "" }
      })
    );
  } else {
    let detailDesc = selectedOption && selectedOption.detailDesc;

    dispatch(
      setPersonalizedQuestionsProgressAction({
        font: { ...progressState.font, [question]: detailDesc }
      })
    );
  }
};
