import React, { useRef, useEffect, useState } from "react";
import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";

import classes from "./BundleItemsMessage.module.css";
import Button from "./Button";
import close from "../../../../../assets/icons/close.svg";
import Modal from "@material-ui/core/Modal";

export default function BundleItemsMessage({
  dispatch,
  displayBundleItemsMessage,
  bundleItems,
  itemId,
  bundleItemParentId,
  itemTitle,
  onClosed
}) {
  const handleClose = () => {
    onClosed();
    dispatch(
      setPersonalizedQuestionsProgressAction({
        displayBundleItemsMessage: false
      })
    );
  };

  const [bundleItemParentTitle, setBundleItemParentTitle] = useState(itemTitle);

  useEffect(() => {
    if (itemId == bundleItemParentId) {
      setBundleItemParentTitle(itemTitle);
    }
  }, [bundleItemParentId, itemId]);

  const btnRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (btnRef.current) {
      setTimeout(() => {
        btnRef.current.focus();
      }, 100);
    }
  }, [btnRef.current]);

  const handleOnBlur = e => {
    // e.target.focus();
  };

  if (displayBundleItemsMessage)
    return (
      <Modal open={displayBundleItemsMessage}>
        <div
          ref={modalRef}
          className={classes.wrapper}
          aria-modal="true"
          role="dialog"
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <em
            tabIndex="0"
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              padding: "5px",
              cursor: "pointer"
            }}
          >
            <img
              src={close}
              onClick={handleClose}
              alt="Icône de fermeture"
            ></img>
          </em>
          <p
            id="modal-title"
            style={{
              marginBottom: "10px",
              fontSize: "2.5em",
              fontWeight: "600"
            }}
            dangerouslySetInnerHTML={{ __html: bundleItemParentTitle }}
          ></p>
          <div id="modal-description">
            {" "}
            <p className={classes.subHeading}>
              Cette trousse tout-en-un contient les éléments essentiels dont
              vous avez besoin pour gérer votre nouveau compte professionnel.
            </p>
            <p className={classes.subHeading}>
              Suivez les étapes pour créer votre trousse:
            </p>
            <ol>
              {bundleItems.map(item => (
                <li
                  key={item.id}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                >
                  {/*     &nbsp;<span>{`(Quantity: ${item.qty})`}</span> */}
                </li>
              ))}
            </ol>
          </div>
          <Button
            onBlur={handleOnBlur}
            btnRef={btnRef}
            onClick={handleClose}
            title="Continuer"
            primary={true}
          >
            Continuer
          </Button>
        </div>
      </Modal>
    );
  else return null;
}
