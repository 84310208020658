export const SECTION_BUTTONS = [
  {
    name: "quantity",
    title: "Quantité",
    desc: "quantity",
    progress: 0
  },
  {
    name: "checkInformationSection",
    title: "Surimpression",
    desc: "Please enter your imprint information:",
    progress: 1
  },
  { name: "checkDesignSection", title: "Conception", progress: 2 },
  { name: "checkReviewSection", title: "Vérifier", progress: 3 }
];
